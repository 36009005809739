import React from 'react';
import './App.css';
import logo from './assets/logo.png';

function App() {
  return (
    <div className="container">
      <div className="logo">
        <img src={logo} alt="Zamzama Enterprises Logo" />
      </div>
      <h1>Coming Soon</h1>
      <p>We are working hard to launch our website. Stay tuned!</p>
    </div>
  );
}

export default App;
